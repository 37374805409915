import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";
import axios from "axios";
import {SERVER_URL} from "../../../serverConfig";
import {getAuthHeader} from "../../../jwt/config";
import fileDownload from "js-file-download";




//Parametrage
export const listEstivageParamAPI = createAsyncThunk(
  "estivage/list",
  async (data) => {
    const url = `/api/estivage/listeParam`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const saveEstivageParamAPI = createAsyncThunk(
  "estivage/save",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/estivage/save", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);
export const savePeriodeEstivageParamAPI = createAsyncThunk(
  "estivage/savePeriodeEstivageParamAPI",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/estivage/savePeriode", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);
export const saveVilleEstivageParamAPI = createAsyncThunk(
  "estivage/saveVilleEstivageParamAPI",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/estivage/saveVille", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);

export const deleteEstivageParamAPI = createAsyncThunk(
  "estivage/delete",
  async (id) => {
    const response = await clientAPI.post("/api/estivage/delete/" + id);
    return response.data;
  }
);
export const deleteVilleEstivageParamAPI = createAsyncThunk(
  "estivage/deleteVilleEstivageParamAPI",
  async (id) => {
    const response = await clientAPI.post("/api/estivage/deleteVille/" + id);
    return response.data;
  }
);
export const deletePeriodeEstivageParamAPI = createAsyncThunk(
  "estivage/deletePeriodeEstivageParamAPI",
  async (id) => {
    const response = await clientAPI.post("/api/estivage/deletePeriode/" + id);
    return response.data;
  }
);

// Gestion
export const listEstivageDemandeAPI = createAsyncThunk(
  "estivage/listDemande",
  async (data) => {
    const url = `/api/estivage/listeDemande`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const listTypeLogementAPI = createAsyncThunk(
  "estivage/listTypeLogemant",
  async (data) => {
    const url = `/api/estivage/listTypeLogemant`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listVilleEstivage = createAsyncThunk(
  "estivage/listVilleEstivage",
  async (data) => {
    const url = `/api/estivage/listeVille`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const listPeriodeEstivage = createAsyncThunk(
  "estivage/listPeriodeEstivage",
  async (data) => {
    const url = `/api/estivage/listePeriode`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const placeDispoAPI = createAsyncThunk(
  "estivage/placeDispoAPI",
  async (data) => {
    const url = `/api/estivage/PlaceDisponible`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const periodeOuvertAPI = createAsyncThunk(
  "estivage/periodeOuvertAPI",
  async (data) => {
    const url = `/api/estivage/periodeOuvert`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const saveDemandeEsivageAPI = createAsyncThunk(
  "estivage/SaveDemandeEstivage",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/estivage/saveDemande", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);

export const listTypeFichierEstivegeAPI = createAsyncThunk(
  "estivage/listTypeFichierEstivage",
  async (data) => {
    const url = `/api/estivage/listeTypeFichier`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listMotifRefusAPI = createAsyncThunk(
  "estivage/listMotifRefusAPI",
  async (data) => {
    const url = `/api/estivage/listMotifRefus`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listTypeFichierSavedAPI = createAsyncThunk(
  "estivage/listeTypeFichierSaved",
  async (idDemande) => {
    const response = await clientAPI.get("/api/estivage/listeTypeFichierSaved/" + idDemande);
    return response.data;
  }
);
export const demandeByAnneeAPI = createAsyncThunk(
  "estivage/demandeByAnneeAPI",
  async (idCompte) => {
    const response = await clientAPI.get("/api/estivage/demandeAnnee/" + idCompte);
    return response.data;
  }
);
export const placeByStatutAdhAPI = createAsyncThunk(
  "estivage/placeByStatutAdhAPI",
  async (idCompte) => {
    const response = await clientAPI.get("/api/estivage/placeByStatut/" + idCompte);
    return response.data;
  }
);
export const listDemandeDerinierAnneeAPI = createAsyncThunk(
  "estivage/listDemandeDerinierAnneeAPI",
  async (idCompte) => {
    const response = await clientAPI.get("/api/estivage/demandeDernierAnnee/" + idCompte);
    return response.data;
  }
);

export const refusDemandeAPI = createAsyncThunk(
  "estivage/refusDemandeAPI",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data)
    formData.append("idDemande", data.idDemande);
    formData.append("motifRefus", data.motifRefus);
    console.log('Formater data ::: ',formData)
    const response = await clientAPI.post("/api/estivage/refusDemande", formData);
    return response.data;
  }
);

export const accepterDemandeAPI = createAsyncThunk(
  "estivage/refusDemandeAPI",
async ({ id }, thunkAPI) => {
  try {
    const url = `/api/estivage/accepterDemande/${id}`;
    const response = await clientAPI.post(url);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
}
);

export const listDemandeFilterAPI = createAsyncThunk(
  "estivage/listDemandeFilterAPI",
  async (data) => {
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/estivage/listDemandeFilter",data);
    return response.data;
  }
);

export const getFileByDemandeAndType = createAsyncThunk(
  "estivage/getFileByDemandeAndType",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/estivage/getFileByDemandeAndType/${data.idDemande}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        if (data.id_type_fichier == 6)
          fileDownload(res.data, data.libelle + ".jpeg");
        else fileDownload(res.data, data.libelle + ".pdf");
      });
  }
);

export const saveFichierEstivageAPI = createAsyncThunk(
  "estivage/saveFichier",
  async (data) => {
    const fichi = JSON.stringify(data.toSave);

    const blob = new Blob([fichi], {
      type: "application/json",
    });

    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    formData.append("fileMeta", blob);
    let token = localStorage.getItem("token");

    const response = axios.post(SERVER_URL + `/api/estivage/saveFichier`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      Accept: "application/json",
    });
    return (await response).data;
  }
);

const Estivage = createSlice({
  name: "estivage",
  initialState: {
    liste: [],
    listeDemande : [],
    listeLogement : [],
    listeTypeFichier:[],
    listePeriodeEstivage:[],
    listeVilleEstivage:[],
    listeTypeFichierSaved:[],
    savedDemande:{},
    listMotifRefus:[],
    periodeOuvert:null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listEstivageParamAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.liste = action.payload;
      })
      .addCase(listEstivageDemandeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeDemande = action.payload;
        console.log('liste demandes ::: ',action.payload)
      })
      .addCase(saveDemandeEsivageAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeTypeFichierSaved = [];
      })
      .addCase(listTypeLogementAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeLogement = action.payload;
      })
      .addCase(listDemandeFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeDemande = action.payload;
      })
      .addCase(listTypeFichierEstivegeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeTypeFichier = action.payload;
      })
      .addCase(listTypeFichierSavedAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeTypeFichierSaved = action.payload;
      })
      .addCase(listMotifRefusAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listMotifRefus = action.payload;
        console.log("Liste motif refus  :: ",action.payload)
      })
      .addCase(listVilleEstivage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeVilleEstivage = action.payload;
      })
      .addCase(listPeriodeEstivage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listePeriodeEstivage = action.payload;
        console.log("Liste periode estivage   :: ",action.payload)
      })

  },
});


export const {} = Estivage.actions;

export default Estivage.reducer;
